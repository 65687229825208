const layerStyles = {
    container: {
        bg: "white",
        borderWidth: "1px",
        borderRadius: "base",
        padding: [4, 8],
        borderColor: "gray.200",
        w: "100%",
    },
    "table-container": {
        bg: "white",
        pt: 2,
        borderWidth: "1px",
        borderRadius: "base",
        borderColor: "gray.200",
        w: "100%",
        maxW: "container.xl",
    },
    "highlighted-content": {
        backgroundColor: "gray.50",
        borderColor: "gray.200",
        borderStyle: "solid",
        borderWidth: "2px",
    },
    "clickable-accordion-row": {
        py: 4,
        pl: 1,
        pr: 4,
        borderBottomWidth: "1px",
        borderBottomColor: "gray.100",

        h2: {
            fontSize: "lg",
            fontWeight: "600",
            display: "inline",
            verticalAlign: "bottom",
        },
    },
    "clickable-accordion-row--interactive": {
        py: 4,
        pl: 1,
        pr: 4,
        cursor: "pointer",
        borderBottomWidth: "1px",
        borderBottomColor: "gray.100",

        _hover: {
            backgroundColor: "#FAFAFA",
        },

        h2: {
            fontSize: "lg",
            fontWeight: "600",
            display: "inline",
            verticalAlign: "bottom",
        },
    },
    centered: {
        w: "full",
        maxW: "container.xl",
        mx: "auto",
    },
    "page-wrapper": {
        py: "4",
        px: ["4", "4", "8"],
        maxW: "container.xl",
        mx: "auto",
        w: "full",
    },
};

export default layerStyles;
