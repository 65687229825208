import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import config from "./config";
import foundations from "./foundations";
import components from "./components";
import layerStyles from "./layerStyles";
import textStyles from "./textStyles";
import styles from "./styles";

const unearthedTheme = extendTheme(
    withDefaultColorScheme({
        colorScheme: "brand",
    }),
    {
        config,
        ...foundations,
        components,
        layerStyles,
        textStyles,
        styles,
    },
);

export default unearthedTheme;
