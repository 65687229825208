import { GenIcon as OriginalGenIcon } from "react-icons";

/**
 * The packaging of react-icons includes all icons from all libraries, if you use one. Not sure why this can't
 * be tree-shaken by vite, but alas they are not. The solution: fork each icon into its own module and include
 * those from our own repo.
 *
 * These were collected automatically from the node_modules folder with a grep on the names of each icon:
 * `grep -rih -A 2 'export function AiFillInfoCircle (' .`.
 */
export function TypeIgnoreGenIcon(data: any) {
    return OriginalGenIcon(data);
}
