import { defaultEditorStyles } from "./defaultEditorStyles";
import { editorialStyles } from "./editorialStyles";

const styles = {
    global: {
        ":root": {
            // Define custom CSS variables here.
            // E.g. "--unearthed-navbar-height": "89px"
            // Which can be used as var(--unearthed-navbar-height).
            "--ue-article-sidebar-width": "280px",
        },
        body: {
            bg: "transparent",
        },

        "body div#root:not(#root #root)": {
            minH: "100vh",
        },

        "[data-youtube-video] iframe": {
            aspectRatio: "16/9",
            width: "100%",
            height: "auto",
            maxWidth: "560px",
            margin: "auto",
        },

        ".html-content": {
            // Put margin above the element relative to it's size
            "& > * + *": {
                mt: "2em",
            },
            // But if the element has a p, ul, ol above it use this fixed smaller margin
            "& > * + p, & > * + ul, & > * + ol": {
                mt: 4,
            },
            table: {
                width: "100% !important",
            },
            a: {
                textDecoration: "underline",
                fontWeight: "600",
                "&:hover": {
                    color: "black",
                },
            },
            h1: {
                fontSize: ["2xl", "2xl", "3xl"],
                fontWeight: 700,
                color: "gray.800",
                lineHeight: "1.3",
            },
            h2: {
                fontSize: ["lg", "lg", "xl"],
                fontWeight: 700,
                color: "gray.800",
                lineHeight: "1.3",
            },
            h3: {
                fontSize: "md",
                fontWeight: 600,
                color: "gray.800",
                lineHeight: "1.3",
            },
            h4: {
                fontSize: "sm",
                fontWeight: 600,
                color: "gray.800",
                lineHeight: "1.3",
            },
            "ul, ol": {
                display: "block",
                marginBlockStart: 4,
                marginBlockEnd: 4,
                marginInlineStart: 0,
                marginInlineEnd: 0,
                paddingInlineStart: 8,

                "@media screen and (max-width: var(--chakra-sizes-md))": {
                    paddingInlineStart: 6,
                },
            },
        },

        ".drag-scroll-snap": {
            display: "flex",
            alignItems: "stretch",
        },

        ".drag-scroll-snap--interactive": {
            cursor: "grab",
        },

        ".drag-scroll-snap--controls-in-use.drag-scroll-snap--horizontal": {
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
        },

        ".drag-scroll-snap--controls-in-use.drag-scroll-snap--vertical": {
            scrollSnapType: "y mandatory",
            scrollBehavior: "smooth",
        },

        ".drag-scroll-snap.indiana-scroll-container--dragging": {
            scrollSnapType: "none",
            cursor: "grabbing",
        },

        ".drag-scroll-snap--vertical": {
            flexDirection: "column",
        },

        /**
         * Add the default editor styles.
         */
        ...defaultEditorStyles,

        /**
         * Add the editorial styles.
         */
        ...editorialStyles,
    },
};

export default styles;
