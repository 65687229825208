interface WithMessage {
    message: string;
}

/**
 * Helper for turning anything into an error string.
 *
 * @param error Anything that might contain an error (Error, object, or string).
 * @param defaultMessage Default error string to show if `error` isn't in any expected shape.
 * @returns An error message.
 */
export function getErrorString(error: unknown, defaultMessage: string = "Something went wrong"): string {
    // If the given error is an array, attempt to get the error string of the
    // first item in the array.
    if (Array.isArray(error)) {
        return getErrorString(error[0]);
    } else if (error instanceof Error) {
        return error.message;
    } else if (
        !!error &&
        (error as WithMessage).message !== undefined &&
        typeof (error as WithMessage).message === "string"
    ) {
        return (error as WithMessage).message;
    } else if (typeof error === "string") {
        return error;
    } else {
        return defaultMessage;
    }
}
