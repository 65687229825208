import colors from "./colors";
import sizes from "./sizes";
import typography from "./typography";

const foundations = {
    colors,
    sizes,
    ...typography,
};

export default foundations;
