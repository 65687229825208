import React, { useEffect, useState } from "react";
import Def from "react-def";

/*
 *
 * Can be used to delay the rendering of a component.
 * NOTE: Using this can make the content flash in, and has
 * a delay of 10-60ms
 *
 * This is a workaround for not having rendering priorities
 * yet in React, something that should be solved in React 18.
 *
 * Debouncing the render of a component stops can stop it from
 * blocking the render of other components
 *
 */
export const useDelayedRender = (renderDelay: number) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const t = setTimeout(() => {
            setShouldRender(true);
        }, renderDelay);
        return () => clearTimeout(t);
    }, [renderDelay]);

    return shouldRender;
};

/*
 * Fallback isn't always required as deferring can often be <0.2s
 * It should only be used if the component is not expected to render
 * until after 0.2s and the fallback component is simple
 */
const DeferredRender: React.FC<{ renderDelay?: number; fallback?: React.ReactElement; children?: React.ReactNode }> = ({
    children,
    renderDelay = 0,
    fallback,
}) => {
    const shouldRender = useDelayedRender(renderDelay);

    // Ignore useDelayedRender if renderDelay is 0
    if (renderDelay > 0 && !shouldRender) {
        return fallback || null;
    }

    return <Def placeholder={fallback}>{children}</Def>;
};

export default DeferredRender;
