// Type Predicate https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

// Action generator for reducers
export type Action<T extends string, P> = {
    readonly type: T;
    readonly payload: P;
};
