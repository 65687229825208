// creates a random number generator function.
export function createRandomGenerator(seed: number) {
    const a = 5486230734; // some big numbers
    const b = 6908969830;
    const m = 9853205067;
    let x = seed;
    // returns a random value 0 <= num < 1 (same as Math.random)
    return function (seed = x) {
        // seed is optional. If supplied sets a new seed
        x = (seed * a + b) % m;
        return x / m;
    };
}

// function creates a 32bit hash of a string
export function stringTo32BitHash(str: string) {
    let v = 0;
    for (let i = 0; i < str.length; i += 1) {
        v += str.charCodeAt(i) << i % 24;
    }
    return v % 0xffffffff;
}

// shuffle array using the str as a key.
// should be random if no seed is provided
export function seededRandom<T>(arr: T[], str?: string): T[] {
    const rArr = [];
    const arrCopy = [...arr];
    const random = str ? createRandomGenerator(stringTo32BitHash(str)) : Math.random;
    while (arrCopy.length > 1) {
        rArr.push(arrCopy.splice(Math.floor(random() * arrCopy.length), 1)[0]);
    }
    rArr.push(arrCopy[0]);
    return rArr;
}
