import * as React from "react";
import FocusedLayout from "./layouts/FocusedLayout";
import { Text, Stack } from "@chakra-ui/react";
import Heading from "./Heading";

const MaintenancePage: React.FC = () => {
    return (
        <FocusedLayout title={["Under Maintenance"]}>
            <Stack spacing={1} mb={4}>
                <Heading type="h3">We'll be back soon</Heading>
                <Text>
                    We are current doing some maintenance and will be back online shortly. Thank you for being patient.
                </Text>
            </Stack>
            <Text color="gray.500">– The Humyn.ai Team</Text>
        </FocusedLayout>
    );
};

export default React.memo(MaintenancePage);
