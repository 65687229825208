import { DocumentNode, OperationVariables, TypedDocumentNode } from "@apollo/client/core";
import { QueryHookOptions, QueryResult } from "@apollo/client/react/types/types";
import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { useCounter } from "@chakra-ui/react";
import { FieldPolicy, KeyArgsFunction, KeySpecifier } from "@apollo/client/cache/inmemory/policies";

export function useDpaPaginatedQuery<TData, TVariables extends OperationVariables>(
    initialItems: number,
    itemsToAppend: number,
    // This is implemented for the niche circumstances where the activity feed has auto-updated a number of items, and
    // we want the next fetch to overlap backwards, to ensure new items don't reduce the total number of new items.
    // This feature could potentially just be removed if it was too complex.
    extraFetchOverlap: number = 0,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options: QueryHookOptions<TData, TVariables>,
    countCachedItems: (data: TData) => number,
): { query: QueryResult<TData, TVariables>; fetchMore: () => void; hasMore: boolean } {
    const queryHook = useQuery<TData, TVariables>(query, {
        ...options,
        variables: {
            ...options?.variables,
            pager: {
                limit: initialItems,
                offset: 0,
            },
        } as any as TVariables,
    });

    // Cached items must be counted so that when paginated components are mounted and unmounted, the initial
    // default value of the offset matches the number of items shown on the screen. Otherwise, invoking fetch
    // more repeats over the same items which are already being displayed.
    const cachedItems = queryHook.data ? countCachedItems(queryHook.data) : null;
    const { increment, valueAsNumber: fetchMoreOffset } = useCounter({
        defaultValue: cachedItems || initialItems,
        step: itemsToAppend,
    });

    // The fetch is not guaranteed to only have new items that are not in the existing list,
    // since new items may have been appended to the start of the list since the query ran.
    // The effect will be clicking "load more" and not seeing any new items. This can be addressed
    // by fetching N more results than "itemsToAppend" and subtracting N from the offset. The result
    // being either more items than were requested or some amount of overlapping items, which are
    // ignored.
    const [hasMore, setHasMore] = useState<boolean>(true);
    const fetchMore = useCallback(() => {
        queryHook
            .fetchMore({
                variables: {
                    pager: {
                        limit: itemsToAppend + extraFetchOverlap,
                        offset: Math.max(0, fetchMoreOffset - extraFetchOverlap),
                    },
                },
            })
            .then(results => {
                if (countCachedItems(results.data) < itemsToAppend + extraFetchOverlap) {
                    setHasMore(false);
                }
            });

        increment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMoreOffset, extraFetchOverlap, itemsToAppend, queryHook, increment]);

    return {
        query: queryHook,
        fetchMore,
        hasMore,
    };
}

export function paginatedFieldPolicyBasedOnIdMerge<T>(
    field: string,
    keyArgs: KeySpecifier | KeyArgsFunction | false = false,
): FieldPolicy<T> {
    return {
        keyArgs: keyArgs,
        merge: (existing: any, incoming: any, { readField }) => {
            const items = [...(existing ? existing : []), ...incoming];
            const uniqueItems = new Map(items.map(item => [parseInt(readField("id", item) as string), item]));
            return [...uniqueItems.entries()].sort((a, b) => b[0] - a[0]).map(item => item[1]) as any;
        },
    };
}
