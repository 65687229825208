import { useEffect, useState } from "react";
import { getToken } from "~/api/graphql";

export function useIsEmailVerified(): boolean | undefined {
    const [emailVerified, setEmailVerified] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        getToken().then(token => {
            setEmailVerified(token?.idTokenDecoded.email_verified);
        });
    }, []);
    return emailVerified;
}
