import { ComponentStyleConfig } from "@chakra-ui/react";

const Alert: ComponentStyleConfig = {
    baseStyle: {
        // Most of the changes here are to improve the alignment
        // with a smaller than intended font size
        container: {
            textStyle: "body",
            alignItems: "center",
        },
        icon: {
            alignSelf: "flex-start",
        },
        title: {
            fontSize: "sm",
            fontWeight: 600,
            lineHeight: "short",
        },
        description: {
            textStyle: "body",
            lineHeight: "short",
        },
    },
    variants: {
        outline: ({ colorScheme }) => ({
            container: {
                borderColor: `${colorScheme}.500`,
                borderWidth: "1px",
                borderRadius: "md",
                backgroundColor: "white",
            },
            title: {
                color: "gray.800",
            },
            description: {
                color: "gray.800",
            },
            icon: {
                color: `${colorScheme}.500`,
            },
        }),
    },
};

export default Alert;
