import Input from "./input";

const Textarea = {
    variants: {
        outline: {
            bg: Input.variants.outline.field.bg,
            borderColor: Input.variants.outline.field.borderColor,
        },
    },
};

export default Textarea;
