import { ComponentStyleConfig } from "@chakra-ui/react";

const Skeleton: ComponentStyleConfig = {
    defaultProps: {
        startColor: "gray.100",
        endColor: "gray.300",
    },
};

export default Skeleton;
