import { ComponentStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentStyleConfig = {
    baseStyle: {
        control: {
            borderColor: "gray.400",
        },
    },
};

export default Checkbox;
