import { Avatar, AvatarProps } from "@chakra-ui/react";
import * as React from "react";
import { Maybe, PublicProfileContent } from "~/types/graphql-dpa";

export default function UserAvatar({
    user,
    ...avatarProps
}: AvatarProps & {
    user:
        | {
              name: string;
              image: Maybe<{ url: string }>;
          }
        | Pick<PublicProfileContent, "name" | "image">;
}) {
    return <Avatar {...avatarProps} name={user.name} src={user.image?.url} />;
}

export function UserAvatarLarge({
    user,
    ...avatarProps
}: AvatarProps & {
    user: {
        name: string;
        image_medium: Maybe<{ url: string }>;
    };
}) {
    return <Avatar {...avatarProps} name={user.name} src={user.image_medium?.url} />;
}
