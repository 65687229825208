import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps, matchPath, Path, useResolvedPath } from "react-router-dom";
import { routes, RoutesItem } from "~/FileRouter";

const getMatchingRoute = (linkPath: Path): RoutesItem | undefined => {
    return routes.find(route => matchPath(route.path || "*", linkPath.pathname));
};

export type LinkProps = RouterLinkProps;

/**
 * A replacement for react-router-dom's Link component that prefetches the route being linked to.
 * It prefetches both when the link becomes visible and when mousing over.
 *
 * It also takes all the params of react-router-dom's Link component.
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ children, to, ...props }: LinkProps, ref) => {
    const linkPath = useResolvedPath(to);
    const [prefetched, setPrefetched] = React.useState(false);
    const route = React.useMemo(() => getMatchingRoute(linkPath), [linkPath]);
    const preload = React.useCallback(() => route?.preload() && setPrefetched(true), [route]);
    const prefetchable = React.useMemo(() => Boolean(route && !prefetched), [prefetched, route]);

    const handleMouseEnter = React.useCallback(() => prefetchable && preload(), [prefetchable, preload]);

    return (
        <RouterLink ref={ref} to={to} onMouseEnter={handleMouseEnter} {...props}>
            {children}
        </RouterLink>
    );
});

export default Link;
