import { PublicProfileContent } from "~/types/graphql-dpa";

export type MicroProfileSchema = Pick<
    PublicProfileContent,
    "account_id" | "name" | "userCountryLabel" | "userCountryCode" | "image" | "alias"
>;
export const microProfileFragment = [
    "account_id",
    "name",
    "userCountryLabel",
    "userCountryCode",
    "image{ url }",
    "alias",
].join("\n");
