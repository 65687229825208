import textStyles from "~/theme/textStyles";

const richEditorStyles = {
    ...textStyles.body,

    "&[contenteditable='true']": {
        p: "2",
    },

    // Placeholder
    "p.is-editor-empty": {
        "&::before": {
            content: "attr(data-placeholder)",
            float: "left",
            pointerEvents: "none",
            height: 0,
            color: "gray.600",
        },
    },

    "h2, h3, h4, h5": {
        mt: "2em",
        mb: "0.5em",
    },

    ">": {
        // Add some space between every node in the editor.
        "*": {
            my: "1em",
        },

        "&:first-of-type:not(style):not(:first-of-type ~ *)": {
            mt: "0px!important",
        },
        "&:last-child": {
            mb: "0px!important",
        },

        // Make top-level blockquotes slightly transparent.
        blockquote: {
            opacity: 0.75,
        },
    },

    // Typography
    "h2, h3, h4": {
        fontWeight: "bold",
    },
    h2: { fontSize: "var(--chakra-fontSizes-lg)" },
    h3: { fontSize: "var(--chakra-fontSizes-md)" },
    h4: { fontSize: "var(--chakra-fontSizes-sm)" },

    // Style the layout of the heading with link
    ".headingWithLinkViewWrapper": {
        "> *": {
            display: "flex",
            a: {
                visibility: "hidden",
                pr: "4px",
                ml: "-1em",
            },
            ":hover": {
                a: {
                    visibility: "visible",
                },
            },
        },
    },

    // Link
    a: {
        textDecoration: "underline",
    },

    // Blockquote
    blockquote: {
        borderWidth: "0 0 0 3px",
        borderColor: "gray.300",
        pl: "var(--chakra-space-6)",
        ">": {
            "*": {
                my: "1em",
            },
        },
    },

    // Table styles
    table: {
        borderWidth: "1px",
        borderColor: "gray.400",
        overflow: "hidden",
        width: "100%",
        "th, td": {
            minW: "1em",
            borderWidth: "1px 0px 0px 1px",
            borderColor: "gray.400",
            padding: "8px",
            // Required to be static, not relative, so borders show up in Firefox,
            // but also so dragging across cells works.
            position: "relative",
            verticalAlign: "top",
        },
        th: {
            bg: "gray.200",
            textAlign: "left",
        },
        td: {
            bg: "white",
        },

        ".selectedCell:after": {
            zIndex: "2",
            position: "absolute",
            content: "''",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            background: "brand.100",
            pointerEvents: "none",
        },

        ".column-resize-handle": {
            position: "absolute",
            right: "-2px",
            top: "0",
            bottom: "-2px",
            width: "4px",
            backgroundColor: "blue.300",
            pointerEvents: "none",
        },
    },
    ".tableWrapper": {
        overflowX: "auto",
    },
    ".resize-cursor": {
        cursor: "col-resize",
    },

    ".ProseMirror-selectednode": {
        outline: "3px solid var(--chakra-colors-brand-100)",
    },

    /**
     * For the codeblock and inline code styles below,
     * we're leaning heavily on the theme we're using
     * from highlight.js to provide colors etc.
     */

    // Codeblock styles
    ".codeblock-node-view-wrapper": {
        position: "relative",

        // Make the copy button hidden by default, and
        // visible when you hover over the container.
        ":hover": {
            button: {
                visibility: "visible",
            },
        },
        button: {
            visibility: "hidden",
            position: "absolute",
            right: "2",
            top: "2",
        },
    },

    pre: {
        background: "white",
        padding: "1em",
        borderRadius: "md",
        fontSize: "xs",
        boxShadow: "rgb(0 0 0 / 10%) 0px 0px 4px 0px inset",
        border: "1px solid var(--chakra-colors-gray-200)",
        maxH: "600px",
        overflow: "auto",

        code: {
            p: "0",
            overflow: "unset",
            whiteSpace: "pre",
        },
    },

    // Inline code styles
    p: {
        "code:not(.block-code)": {
            display: "inline",
            padding: "0.2em 0.4em",
            fontSize: "85%",
            borderRadius: "md",
            background: "blackAlpha.100",
        },
    },

    "ul, ol": {
        display: "block",
        marginBlockStart: 4,
        marginBlockEnd: 4,
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: 8,

        "@media screen and (max-width: var(--chakra-sizes-md))": {
            paddingInlineStart: 6,
        },
    },

    ".centered-image": {
        width: "560px",
        maxW: "100%",
        margin: "auto",
        border: "1px solid #e8e8e8",
    },
};

export const defaultEditorStyles = {
    ".ProseMirror": richEditorStyles,
    ".rich-html": richEditorStyles,

    /**
     * Styles specifically for the react-hook-form editor.
     */
    ".react-hook-form-rich-editor": {
        minHeight: "30vh",
        maxHeight: "80vh",
        overflowY: "auto",
        resize: "vertical",
        background: "var(--chakra-colors-white)",
        borderRadius: "0 0 var(--chakra-radii-md) var(--chakra-radii-md)",
        border: "1px solid var(--chakra-colors-gray-200)",
        padding: "var(--chakra-space-2)",
    },

    ".react-hook-form-rich-editor--disabled": {
        opacity: 0.4,
        cursor: "not-allowed",
    },
};
