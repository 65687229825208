import * as React from "react";
import { Mixpanel } from "mixpanel-browser";

export const MixpanelContext = React.createContext<Mixpanel | null>(null);

interface Props {
    mixpanel: Mixpanel | null;
    children?: React.ReactNode;
}

export const MixpanelProvider: React.FC<Props> = ({ children, mixpanel }) => {
    return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
};

export const MixpanelConsumer = MixpanelContext.Consumer;
export const useMixpanel = () => React.useContext(MixpanelContext);
