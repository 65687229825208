import * as React from "react";
import { useMixpanel } from "./MixpanelContext";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";

const useIdentifyUsers = () => {
    const { currentUser, isLoading } = useCurrentDpaUser();
    const mixpanel = useMixpanel();

    React.useEffect(() => {
        if (isLoading) return;

        if (currentUser && mixpanel) {
            // Map the information we are sending to Mixpanel.
            const currentUserInfo = {
                userId: currentUser.account_id,
                fullName: currentUser.content.name,
                userCountryLabel: currentUser.content.userCountryLabel,
            };
            // Primarily identify users based on the cognito uuid, alternatively for some special accounts, like the
            // maintenance account or some legacy CMS accounts, no cognito uuid will exist, so identify by the numeric
            // CMS ID.
            mixpanel.identify(currentUser.account_id);
            mixpanel.people.set(currentUserInfo);
        }
    }, [currentUser, isLoading, mixpanel]);
};

export default useIdentifyUsers;
