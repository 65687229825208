const Popover = {
    baseStyle: {
        body: {
            textStyle: "body",
        },
    },
    variants: {
        responsive: {
            popper: {
                maxWidth: "unset",
                width: "unset",
            },
        },
    },
    sizes: {
        md: {
            popper: {
                maxW: "16rem",
            },
        },
    },
};

export default Popover;
