import { RouteData } from "~/FileRouter";
import { useParams } from "react-router-dom";
import useInnovatorJob from "~/products/job-participation/hooks/useInnovatorJob";
import identifierBelongsToJob from "~/products/job-participation/helpers/identifierIsJob";

const data: RouteData = {
    accessRequired: [],
    usePrefetchHttp: () => {
        const { competitionId } = useParams();
        if (identifierBelongsToJob(competitionId!)) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useInnovatorJob(competitionId!);
        }
    },
};

export default data;
