import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { MixpanelProvider } from "~/common/analytics";
import mixpanel from "mixpanel-browser";
import config from "./config/config";
import { HelmetProvider } from "react-helmet-async";
import localMixpanelLogger from "~/helpers/localMixpanelLogger";

if (config.mixpanelProjectToken) {
    mixpanel.init(config.mixpanelProjectToken);
}

const root = createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <HelmetProvider>
                <MixpanelProvider mixpanel={config.mixpanelProjectToken ? mixpanel : localMixpanelLogger}>
                    <Router>
                        <App />
                    </Router>
                </MixpanelProvider>
            </HelmetProvider>
        </ChakraProvider>
    </React.StrictMode>,
);
