import { Heading as ChakraHeading, HeadingProps as ChakraHeadingProps } from "@chakra-ui/react";

export interface HeadingProps extends ChakraHeadingProps {
    type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Heading = ({ type, children, ...props }: HeadingProps) => {
    return (
        <ChakraHeading as={type} size={type} {...props}>
            {children}
        </ChakraHeading>
    );
};

export default Heading;
