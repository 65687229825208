import { IconBaseProps } from "react-icons";
import { TypeIgnoreGenIcon as GenIcon } from "~/common/icons/index";
export function BsList(props: IconBaseProps) {
    return GenIcon({
        tag: "svg",
        attr: { fill: "currentColor", viewBox: "0 0 16 16" },
        child: [
            {
                tag: "path",
                attr: {
                    fillRule: "evenodd",
                    d: "M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z",
                },
            },
        ],
    })(props);
}
