import { Box, Link, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import Logo from "../icons/Logo";
import MetaHead, { titleArray } from "../MetaHead";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    title: titleArray;
    includeSupport?: boolean;
    children?: React.ReactNode;
}

const FocusedLayout: React.FC<Props> = ({ children, title, includeSupport = false }) => {
    return (
        <Stack bg="gray.50" spacing={4} flexGrow={1} as="main" alignItems="center" px={[4, 4, 8]} py={16}>
            <MetaHead title={title} />
            <Link as={RouterLink} to="/" aria-label="Home" mb={6}>
                <Box h={10}>
                    <Logo height="100%" />
                </Box>
            </Link>
            <Box layerStyle="container" maxW="lg">
                {children}
            </Box>
            {includeSupport && (
                <Text color="gray.600" fontSize="xs">
                    Having trouble?{" "}
                    <Link fontSize="xs" color="gray.600" href="mailto:support@humyn.ai">
                        Contact support
                    </Link>
                </Text>
            )}
        </Stack>
    );
};

export default FocusedLayout;
