import { gql } from "@apollo/client";
import useToastedErrorMutation from "~/api/utils/useToastedErrorMutation";
import { dpaClient } from "~/api/graphql";
import { Mutation, MutationUpdate_ProfileArgs } from "~/types/graphql-dpa";

interface Response {
    update_persona: Mutation["update_profile"];
}

export default function useUpdatePersona() {
    const [updatePersona, result] = useToastedErrorMutation<Response, MutationUpdate_ProfileArgs>(
        gql`
            mutation MutationUpdateProfile($persona: String!) {
                update_profile(data: { persona: $persona }) {
                    violations {
                        field
                        message
                    }
                }
            }
        `,
        {
            client: dpaClient,
        },
    );
    return { updatePersona, result };
}
