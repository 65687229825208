import config from "~/config/config";

// Can be set to "?XDEBUG_SESSION=phpstorm", to trigger debugs where xdebug cookies are not sent.
const debugParams = "";

async function post(uri: string, data: object | null, extraHeaders: { [key: string]: string } | null = null) {
    let headers = {
        "Content-Type": "application/json",
        ...(extraHeaders ? extraHeaders : {}),
    };
    return await fetch(config.authServiceUrl + uri + debugParams, {
        credentials: "include",
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    });
}

async function get(uri: string, options?: RequestInit) {
    const headers = {
        "Content-Type": "application/json",
    };

    const mergedOptions: RequestInit = {
        credentials: "include",
        method: "GET",
        headers: headers,
        ...options,
    };

    return await fetch(config.authServiceUrl + uri + debugParams, mergedOptions);
}

export function fetchToken() {
    return get("/cognito/auth-token", {
        credentials: "include",
        method: "GET",
    });
}

export async function login(username: string, password: string) {
    return post("/cognito/user/login", {
        name: username,
        pass: password,
    });
}

export function register(
    email: string,
    fullName: string,
    referral: string | null,
    persona: string,
    password: string,
    captchaSolution: string,
) {
    return post(
        "/cognito/user/register",
        {
            // name is the username
            name: { value: email },
            mail: { value: email },
            field_full_name: { value: fullName },
            field_referral: { value: referral },
            field_registered_persona: { value: persona },
            pass: { value: password },
        },
        {
            "X-Captcha-Solution": captchaSolution,
        },
    );
}

export function logout() {
    return post("/cognito/user/logout", null);
}

export function verifyEmail(code: string) {
    return post("/verify-code", {
        code: code,
    });
}

export function sendPasswordReset(email: string) {
    return post("/cognito/reset-password", {
        email,
    });
}

export function confirmPasswordReset(email: string, newPassword: string, confirmationCode: string) {
    return post("/cognito/confirm-password-reset", {
        email,
        newPassword,
        confirmationCode,
    });
}

export function sendVerificationCode() {
    return post("/send-verify-code", null);
}

export function updateEmail(password: string, newEmail: string) {
    return post("/cognito/update-email", {
        password,
        newEmail,
    });
}

export function updatePassword(password: string, newPassword: string) {
    return post("/cognito/update-password", {
        password,
        newPassword,
    });
}
