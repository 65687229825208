import { Persona, PersonaAction, PersonaState } from "~/products/persona-toggle/hooks/usePersonaState";
import { Dispatch, useEffect } from "react";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";

export default function useHydratePersonaStateFromCms(state: PersonaState, dispatch: Dispatch<PersonaAction>) {
    const { currentUser } = useCurrentDpaUser();
    useEffect(() => {
        if (currentUser?.private_content.persona) {
            dispatch({
                name: "PERSONA_HYDRATED_FROM_CMS_CURRENT_USER_QUERY",
                persona:
                    currentUser?.private_content.persona === "industry_leader" ? Persona.Industry : Persona.Innovator,
            });
        }
    }, [currentUser?.private_content.persona, dispatch]);
}
