import * as React from "react";
import { useLocation } from "react-router-dom";

/**
 * Utility component that scrolls to the top of the page whenever the route changes.
 */
export default function ScrollToTop() {
    const location = useLocation();
    const previousLocation = React.useRef(location);

    React.useLayoutEffect(() => {
        if (location.state?.scrollToTop === false) return;
        // We only want this behaviour to affect navigation between pages,
        // not navigation within pages (i.e. hash links).
        if (location.pathname !== previousLocation.current?.pathname) {
            window.scrollTo(0, 0);
        }
        previousLocation.current = location;
    }, [location]);

    return null;
}
