import { ComponentStyleConfig } from "@chakra-ui/react";

const Link: ComponentStyleConfig = {
    baseStyle: {
        textStyle: "body",
        textDecoration: "underline",
        color: "gray.800",
        fontWeight: "600",
        _hover: {
            color: "black",
        },
    },
};

export default Link;
