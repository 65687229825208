import { FallbackProps } from "react-error-boundary";
import { Box, Button, Center, Code, Flex, HStack, Image, SimpleGrid, Spinner, Stack } from "@chakra-ui/react";
import { CenteredContainer } from "./Container";
import Heading from "./Heading";
import HeroLarge from "../assets/HeroLarge.jpeg";
import * as React from "react";
import { useLogError } from "../hooks/useLogError";

// Certain types of errors might benefit from simply force refreshing the page. In cases where
// we have deployed the front-end and rebuilt all the chunk names, the one fix is to reload the
// page, so we may as well do that for the user automatically.
const forceReloadErrors = [/dynamically imported module/i];

export function ErrorFallback({ error }: FallbackProps) {
    const logError = useLogError(false);

    React.useEffect(() => {
        logError(error.message);
    }, [error.message, logError]);

    const isForceReloadError = forceReloadErrors.some(re => re.test(error.message));
    React.useEffect(() => {
        if (isForceReloadError) {
            // @ts-ignore - true indicates a hard-reload in some browsers, but it is non-standards compliant.
            window.location.reload(true);
        }
    }, [isForceReloadError]);

    if (isForceReloadError) {
        return (
            <Flex alignItems="center" justifyContent="center" minH="100vh">
                <Spinner />
            </Flex>
        );
    }

    return (
        <CenteredContainer pt={8}>
            <SimpleGrid columns={[1, 1, 1, 2]} gap={16}>
                <Stack justifyContent="center" spacing={8}>
                    <Heading as="h1" type="h2">
                        Something went wrong
                    </Heading>
                    <Heading type="h4">We'll get this fixed! Until then, you could try refreshing.</Heading>
                    <Box overflowX="auto">
                        <Code colorScheme="red" alignSelf="flex-start">
                            {error.message}
                        </Code>
                    </Box>
                    <HStack>
                        <Button onClick={() => window.location.reload()}>Refresh</Button>
                    </HStack>
                </Stack>
                <Center>
                    <Image src={HeroLarge} alt="Page not found" />
                </Center>
            </SimpleGrid>
        </CenteredContainer>
    );
}
