import { gql, useQuery } from "@apollo/client";
import { Maybe, PublicProfile, PublicProfileContent, SocialLinks } from "~/types/graphql-dpa";
import { dpaClient } from "~/api/graphql";

export type CurrentUserPublicProfile = Pick<PublicProfile, "account_id" | "private_content"> & {
    content: Pick<
        PublicProfileContent,
        "account_id" | "name" | "about" | "alias" | "userCountryCode" | "userCountryLabel" | "city" | "image"
    > & {
        socialLinks: Pick<SocialLinks, "linkedin">;
    };
};

export function useCurrentDpaUser() {
    const query = useQuery<{
        current_user_profile: Maybe<CurrentUserPublicProfile>;
    }>(
        gql`
            query CurrentUser {
                current_user_profile {
                    account_id

                    content {
                        account_id
                        name
                        about
                        alias
                        userCountryCode
                        userCountryLabel
                        city
                        image {
                            url
                        }

                        socialLinks {
                            linkedin
                        }
                    }

                    private_content {
                        account_id
                        dismissedContent
                        persona
                        roles
                    }
                }
            }
        `,
        {
            client: dpaClient,
        },
    );

    const roles = query.data?.current_user_profile?.private_content.roles ?? [];
    const isAdmin = roles.includes("ROLE_PLATFORM_MANAGER") || roles.includes("ROLE_ADMIN");
    const currentUser = query.data?.current_user_profile;

    return {
        currentUser: currentUser,
        loggedIn: !!currentUser,
        isLoading: query.loading,
        query,
        isAdmin,
    };
}
