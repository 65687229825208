import { Persona, PersonaAction, PersonaState } from "~/products/persona-toggle/hooks/usePersonaState";
import { Dispatch, useEffect } from "react";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";

export default function useResetPersonaAfterLogout(state: PersonaState, dispatch: Dispatch<PersonaAction>) {
    const { isLoading, loggedIn } = useCurrentDpaUser();
    useEffect(() => {
        if (!isLoading && !loggedIn && state.currentPersona === Persona.Industry) {
            dispatch({
                name: "PERSONA_RESET_FOR_LOGGED_OUT_USER",
            });
        }
    }, [isLoading, loggedIn, state.currentPersona, dispatch]);
}
