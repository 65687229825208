import { useEffect } from "react";
import usePersonaState from "~/products/persona-toggle/hooks/usePersonaState";
import { useLocation } from "react-router-dom";

/**
 * This hook covers the use case where someone has been linked to a /projects path, but
 * has not made a choice about persona. This will auto-assign them as industry in this
 * case.
 */
export function useDetectPersonaBasedOnPath() {
    const [, personaDispatcher] = usePersonaState();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.indexOf("/projects") === 0) {
            personaDispatcher({ name: "INDUSTRY_PERSONA_DETECTED_BASED_ON_PROJECTS_PATH" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
}
