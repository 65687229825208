import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Capture a referral code when the ?ref=foo param is provided.
 */
export default function useCaptureReferralCode() {
    const [search] = useSearchParams();
    const ref = search.get("ref");

    useEffect(() => {
        if (ref && ref.length < 72) {
            window.localStorage.setItem("ref", ref);
        }
    }, [ref]);
}

export function getUserReferral(): string | null {
    return window.localStorage.getItem("ref") || null;
}
