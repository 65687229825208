import { Helmet } from "react-helmet-async";
import { notEmpty } from "~/helpers/typeHelpers";
import { useLocation } from "react-router-dom";
import trimEnd from "lodash/trimEnd";

type optionalString = string | null | undefined;
export type titleArray = optionalString[];

const siteName = "Humyn.ai";
const divider = "|";
const spacer = ` ${divider} `;
const ellipsis = "...";
const maxTitleLength = 60;

export function getMetaTitle(title?: titleArray): string {
    // Handling null or undefined values makes it easier to use in practice
    const arr: string[] = title ? [...title].filter(notEmpty) : [];
    let computedTitle = arr.join(spacer);

    // Prevent title from being too long
    const maxCombinedTitleArrayLength = maxTitleLength - siteName.length - spacer.length;
    if (computedTitle.length > maxCombinedTitleArrayLength) {
        computedTitle = computedTitle.slice(0, maxCombinedTitleArrayLength - ellipsis.length).trim();
        // This prevents the title ending with " |..."
        if (computedTitle.slice(-1) === divider) {
            computedTitle = computedTitle.slice(0, -1).trim();
        }
        computedTitle += ellipsis;
    }

    // Just return the site name if there's no title
    computedTitle = computedTitle ? computedTitle + spacer + siteName : siteName;

    return computedTitle;
}

export default function MetaHead({
    title,
    description,
    extraMeta,
    canonical,
}: {
    title?: titleArray;
    description?: optionalString;
    extraMeta?: JSX.Element;
    canonical?: string;
}) {
    const computedTitle = getMetaTitle(title);

    // Use the pathname as the canonical URL of all routes, since query strings are frequently appended
    // by different sources, however our router never uses a query string for negotiating the correct
    // content.
    const { pathname } = useLocation();
    const canonicalPath = canonical || trimEnd(pathname, "/");

    return (
        <Helmet>
            <title>{computedTitle}</title>
            <meta property="og:title" content={computedTitle} />
            {description && <meta property="og:description" content={description} />}
            {description && <meta name="description" content={description} />}
            <link rel="canonical" href={`${window.location.origin}${canonicalPath}`} />
            {extraMeta}
        </Helmet>
    );
}
