import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "~/config/config";
import { paginatedFieldPolicyBasedOnIdMerge } from "~/api/utils/useDpaPaginatedQuery";
import { fetchToken } from "~/api/auth";

export type Tokens = {
    accessToken: string;
    accessTokenDecoded: any;
    idToken: string;
    idTokenDecoded: { email_verified: boolean; email: string };
};

const tokenCache: {
    expiry: number;
    tokens: Tokens | null;
    getTokenInProgress: Promise<any> | null;
} = {
    expiry: 0,
    tokens: null,
    getTokenInProgress: null,
};

function decodeToken(token: string): any {
    const decodedToken = atob(token.split(".")[1]);
    return JSON.parse(decodedToken);
}

/**
 * Used for getting the auth token
 * Gets the token from the cache if it hasn't expired
 */
export function getToken(): Promise<Tokens | null> {
    return new Promise((resolve, reject) => {
        // If the token is in cache and hasn't expired yet, use that.
        if (tokenCache.expiry > new Date().getTime() / 1000) {
            resolve(tokenCache.tokens);
            return;
        }

        if (tokenCache.getTokenInProgress === null) {
            tokenCache.getTokenInProgress = fetchToken().then(response => response.json());
        }

        tokenCache.getTokenInProgress.then(json => {
            if (!json.accessToken) {
                tokenCache.getTokenInProgress = null;
                tokenCache.tokens = null;
                tokenCache.expiry = new Date().getTime() / 1000 + 3600;
                resolve(null);
                return;
            }

            const accessTokenDecoded = decodeToken(json.accessToken);
            tokenCache.expiry = accessTokenDecoded.exp;
            tokenCache.tokens = {
                accessToken: json.accessToken,
                accessTokenDecoded: accessTokenDecoded,
                idToken: json.idToken,
                idTokenDecoded: decodeToken(json.idToken),
            };
            tokenCache.getTokenInProgress = null;

            resolve(tokenCache.tokens);
            return;
        });
    });
}

/**
 * Forces the client to get a new auth token on the next request
 */
export const resetToken = async () => {
    tokenCache.expiry = 0;
    // Clear the caches for the clients
    dpaClient.cache.reset();
};

/**
 * An Apollo Link for getting the DPA auth token
 * and adding it to the request headers
 */
const dpaAuthLink = setContext(async (operation, prevContext) => {
    const token = await getToken();
    if (token) {
        return {
            headers: {
                authorization: `Bearer ${token.accessToken}`,
            },
        };
    }
    return {
        headers: {
            // See docs 32.03 for explanation.
            "Content-Type": "text/plain",
        },
    };
});

/**
 * An Apollo Link for DPA, used for configuring advanced HTTP Networking
 * We use this to add `credentials: "include"`
 */
const dpaHttpLink = createHttpLink({
    uri: config.dpaBaseUrl + "/graphql/",
    credentials: "include",
    useGETForQueries: true,
});

/**
 * The Apollo Client for DPA
 */
export const dpaClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            JobPlacementBucket: {
                // Both the Job ID and the ID of the placement bucket are keys for this type, since bucket IDs might be
                // reused across jobs, ie "winner" or "runner_up".
                keyFields: ["job_id", "id"],
            },
            JobApplication: {
                keyFields: ["applicant_id", "job_id"],
            },
            JobParticipation: {
                keyFields: ["participant_uuid", "job_uuid"],
            },
            RewardStructure: {
                keyFields: false,
            },
            Rewards: {
                keyFields: false,
            },
            ProfileAchievement: {
                keyFields: false,
            },
            ProfileMilestone: {
                keyFields: false,
            },
            EarnedSkill: {
                keyFields: false,
            },
            MarketingPreferences: {
                keyFields: false,
            },
            MarketingPreferencesSubscribedLists: {
                keyFields: false,
            },
            InnovatorJobParticipation: {
                keyFields: false,
            },
            Query: {
                fields: {
                    activity_feed: paginatedFieldPolicyBasedOnIdMerge("id"),
                    remixes: paginatedFieldPolicyBasedOnIdMerge("id"),
                },
            },
        },
    }),
    link: dpaAuthLink.concat(dpaHttpLink),
});
