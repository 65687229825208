import { Stack, Button, Text, BoxProps } from "@chakra-ui/react";
import Link from "~/common/components/PrefetchLink";
import { CenteredContainer } from "./Container";
import Heading from "./Heading";
import MetaHead from "./MetaHead";

export interface PageNotFoundProps extends Omit<BoxProps, "title"> {
    /**
     * The title to render.
     *
     * Defaults to "Page not found".
     */
    title?: string;

    /**
     * The body content to render.
     *
     * Defaults to generic page not found content.
     */
    body?: string;

    /**
     * This is basically a footer. It is intended to show actions that the user can take if they encounter this component.
     *
     * Defaults to showing a "Home" link to `/`.
     */
    actions?: React.ReactNode;
}

/**
 * Helper layout component to render as a fallback in various circumstances.
 *
 * Renders a card with a title, body, and actions.
 */
const PageNotFound = ({
    title = "Page not found",
    body = "It looks like the page you're trying to view doesn't exist.",
    actions,
    ...rest
}: PageNotFoundProps) => {
    return (
        <CenteredContainer {...rest}>
            <MetaHead title={[title]} />
            <Stack spacing="8" align="start">
                <Stack>
                    <Heading type="h2">{title}</Heading>
                    <Text>{body}</Text>
                </Stack>
                {actions ? (
                    actions
                ) : (
                    <Button as={Link} to="/">
                        Home
                    </Button>
                )}
            </Stack>
        </CenteredContainer>
    );
};

export default PageNotFound;
