import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyleContainer: SystemStyleObject = {
    ol: {
        padding: 0,
        margin: 0,
        display: "flex",
        flexWrap: "wrap",
    },
};

const baseStyleLink: SystemStyleObject = {
    color: "gray.600",
    fontWeight: "600",
    fontSize: ["sm", "sm", "md"],
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&[aria-current='page']": {
        color: "gray.800",
    },
};

const baseStyleItem: SystemStyleObject = {
    minW: "0",
};

const baseStyle: PartsStyleObject = {
    container: baseStyleContainer,
    item: baseStyleItem,
    link: baseStyleLink,
};

const Breadcrumb = {
    baseStyle,
};

export default Breadcrumb;
