import textStyles from "~/theme/textStyles";

// We seperate the styles here so they can be extended more easily
export const styles = {
    ...textStyles.editorial,
};

export const editorialStyles = {
    ".editorial.ProseMirror": {
        ...styles,

        "ul, ol": {
            display: "block",
            marginBlockStart: 4,
            marginBlockEnd: 4,
            marginInlineStart: 0,
            marginInlineEnd: 0,
            paddingInlineStart: 8,

            "@media screen and (max-width: var(--chakra-sizes-md))": {
                paddingInlineStart: 6,
            },
        },
    },
};
