import { ComponentStyleConfig } from "@chakra-ui/react";

const Menu: ComponentStyleConfig = {
    baseStyle: {
        list: {
            minW: "5xs",
        },
    },
};

export default Menu;
