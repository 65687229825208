/*
 * For reference our type scale is ~1.2 (Minor Third) with a base size of sm (14px)
 *
 * The main body text varies between md (16px) and sm (14px) depending the content of the page
 * The textStyles editorial and body should be used to do this if possible
 */
const textStyles = {
    h1: {
        fontSize: ["3xl", "3xl", "4xl"],
        fontWeight: 700,
        color: "gray.800",
        lineHeight: "1.3",
    },
    h2: {
        fontSize: ["2xl", "2xl", "3xl"],
        fontWeight: 700,
        color: "gray.800",
        lineHeight: "1.3",
    },
    h3: {
        fontSize: ["lg", "lg", "xl"],
        fontWeight: 700,
        color: "gray.800",
        lineHeight: "1.3",
    },
    h4: {
        fontSize: "md",
        fontWeight: 600,
        color: "gray.800",
        lineHeight: "1.3",
    },
    h5: {
        fontSize: "sm",
        fontWeight: 600,
        color: "gray.800",
        lineHeight: "1.3",
    },
    h6: {
        fontSize: "xs",
        fontWeight: 600,
        color: "gray.800",
        lineHeight: "1.3",
    },
    // Should be used when the text content
    // is around >=2 lines long
    editorial: {
        fontSize: "md",
    },
    // Should be used for the majority of text
    // Only use if the text is around 1-2 lines long
    body: {
        fontSize: "sm",
    },
    // Should be used for supporting/labelling content
    label: {
        fontSize: "xs",
    },

    "secondary-heading": {
        fontSize: "lg",
        fontWeight: "normal",
        color: "gray.500",
    },

    "clickable-accordion-row-supplementary": {
        display: "inline",
        lineHeight: 1.2,
        verticalAlign: "bottom",
        fontSize: "lg",
        fontWeight: "600",
        color: "gray.500",
    },

    tableHeader: {
        color: "gray.600",
        textTransform: "uppercase",
        fontSize: "xs",
        fontWeight: "bold",
    },
};

export default textStyles;
