import { ComponentStyleConfig } from "@chakra-ui/react";

const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: 700,
        color: "gray.800",
        lineHeight: "1.3",
    },
};

export default FormLabel;
