import { ThemeComponents } from "@chakra-ui/react";
import Alert from "./alert";
import Avatar from "./avatar";
import Badge from "./badge";
import Button from "./button";
import Code from "./code";
import FormLabel from "./form-label";
import Heading from "./heading";
import Input from "./input";
import Link from "./link";
import Menu from "./menu";
import Popover from "./popover";
import Spinner from "./spinner";
import Tabs from "./tabs";
import Tag from "./tag";
import Textarea from "./textarea";
import Table from "./table";
import Skeleton from "./skeleton";
import Checkbox from "./checkbox";
import Breadcrumb from "./breadcrumb";
import Text from "./text";
import List from "./list";

const components: ThemeComponents = {
    Alert,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Checkbox,
    Code,
    FormLabel,
    Heading,
    Input,
    Link,
    Menu,
    Popover,
    Skeleton,
    Spinner,
    Table,
    Tag,
    Tabs,
    Textarea,
    Text,
    List,
};

export default components;
