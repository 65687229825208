import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { pathToRoute, RoutePath } from "./helpers/routing";
import SuspenseFallback from "./common/components/SuspenseFallback";
import AppLayout from "./common/components/AppLayout";
import MaintenancePage from "./common/components/MaintenancePage";
import config from "./config/config";
import ProtectedComponent from "~/common/components/ProtectedComponent";
import PageNotFound from "~/common/components/PageNotFound";

export interface RouteData {
    /**
     * Whether the route should be protected from anonymous users.
     *
     * Routes are protected by default, so this is an opt-out.
     */
    accessRequired?: string[];

    usePrefetchHttp?: () => void;

    /**
     * If the router should ensure the user is loaded before displaying the route. This is helpful if the route is
     * highly dynamic between logged in users and anonymous users, but accessible to both. If this is not set, and there
     * are no access requirements on a route, it will immediately be displayed (as to start the waterfall of downloading
     * all sub-components of a route), BEFORE the current user is loaded. This is important to keep core web vitals
     * healthy.
     */
    requireResolvedUser?: boolean;

    /**
     * Whether the route should be wrapped in AppLayout.
     *
     * Routes have layout by default, so this is an opt-out.
     */
    hasAppLayout?: boolean;
    /**
     * The name of a Mixpanel event that should be fired when this route is visited.
     */
    trackMixpanelEvent?: string;

    /**
     * If registration routes should be the redirect when access fails, helpful if you know the user doesn't have an
     * account when being linked to a route (aka, a link in an invitation email to a user that does not have an
     * account).
     */
    preferRegisterForAccessProtection?: boolean;
}

/**
 * The list of modules to include in the file router.
 *
 * Edit this glob to change what files are included (this also ignores test files).
 * These modules are loaded lazily, so thye need to be rendered using a Suspense component.
 */
const modules = import.meta.glob("./pages/**/!(*.test|*.data).(js|jsx|tsx)");

/**
 * The route data for the modules to include in the file router.
 *
 * This only gets files with the extension `.data.ts`.
 * This file is *optional*
 */
const routeDataFiles = import.meta.glob("./pages/**/*.data.ts", { eager: true });
const routeDataFilesLookup = Object.keys(routeDataFiles).reduce<{ [key: string]: RouteData }>((obj, path) => {
    const withoutExtensions = path.replace(/\.data\.ts$/, "");
    // @ts-ignore
    obj[withoutExtensions] = routeDataFiles[path].default;
    return obj;
}, {});

function getRouteData(path: string): RouteData | undefined {
    const withoutExtension = path.replace(/\.(js|jsx|tsx)$/, "");
    return routeDataFilesLookup[withoutExtension] ?? undefined;
}

/**
 * The list of routes (based on files) in the app.
 */
export const routes = Object.keys(modules).map(path => {
    const module = modules[path] as () => Promise<{ default: React.ComponentType<any> }>;
    const routeData = getRouteData(path);
    return {
        Component: lazy(module),
        preload: module,
        path: pathToRoute(path as RoutePath),
        mixpanelEvent: routeData?.trackMixpanelEvent,
        // By default, users must be authenticated in order to view a page.
        accessRequired: routeData?.accessRequired || ["authenticated"],
        hasLayout: routeData?.hasAppLayout === undefined ? true : routeData.hasAppLayout,
        preferRegisterForAccessProtection: routeData?.preferRegisterForAccessProtection,
        requireResolvedUser: routeData?.requireResolvedUser,
        usePrefetchHttp: routeData?.usePrefetchHttp,
    };
});

export type RoutesItem = (typeof routes)[number];

function DataPrefetcher({ useHook }: { useHook: () => void }) {
    useHook();
    return null;
}

/**
 * Callback function to handle rendering routes.
 */
function renderRoutes(props: RoutesItem) {
    return (
        <Route
            element={
                <>
                    {props.usePrefetchHttp && <DataPrefetcher useHook={props.usePrefetchHttp} />}
                    <Suspense fallback={<SuspenseFallback />}>
                        <ProtectedComponent
                            mixpanelEvent={props.mixpanelEvent}
                            accessRequired={props.accessRequired}
                            Component={props.Component}
                            preferRegisterForAccessProtection={props.preferRegisterForAccessProtection}
                            requireResolvedUser={props.requireResolvedUser}
                        />
                    </Suspense>
                </>
            }
            key={props.path}
            path={props.path}
        />
    );
}

const renderedRoutes = routes.filter(r => r.hasLayout).map(renderRoutes);
const noLayoutRoutes = routes.filter(r => !r.hasLayout).map(renderRoutes);

/**
 * The file-based router.
 */
const FileRouter = () => {
    if (config.maintenanceEnabled) return <MaintenancePage />;
    return (
        <Suspense fallback={<SuspenseFallback />}>
            <Routes>
                <Route element={<AppLayout />}>
                    {renderedRoutes}
                    <Route path="*" element={<PageNotFound mt="4" />} />
                </Route>
                {noLayoutRoutes}
            </Routes>
        </Suspense>
    );
};

export default FileRouter;
