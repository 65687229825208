import { ComponentStyleConfig } from "@chakra-ui/react";

const Tabs: ComponentStyleConfig = {
    variants: {
        line: ({ colorScheme }) => ({
            tab: {
                color: "gray.700",
                fontWeight: "semibold",
                _selected: {
                    color: "gray.800",
                    borderBottomColor: `${colorScheme}.500`,
                    fontWeight: "semibold",
                },
            },
            tablist: {
                borderBottomColor: "gray.50",
            },
            tabpanel: {
                px: 0,
            },
        }),
    },
    defaultProps: {
        colorScheme: "brand",
    },
};

export default Tabs;
