type GetPersistedReturn<IsJSON> = StringOrJSON<IsJSON> | null;
type StringOrJSON<IsJSON> = IsJSON extends boolean ? any : string;

export function getPersisted<Bool extends boolean>(id: string, json?: Bool): GetPersistedReturn<Bool> {
    try {
        const item = window.localStorage.getItem(id);
        if (!item) return null;
        return json ? JSON.parse(item) : item;
    } catch (error) {
        // This is pretty standard so we don't log it to rollbar
        console.error(error);
        return null;
    }
}

export function setPersisted<Bool extends boolean>(id: string, value: StringOrJSON<Bool>, json?: Bool): void {
    window.localStorage.setItem(id, json ? JSON.stringify(value) : value);
}
