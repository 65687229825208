const sizes = {
    sm: {
        borderRadius: "md",
    },
    xl: {
        fontSize: "xl",
        px: 4,
        h: 14,
        borderRadius: "md",
    },
    "2xl": {
        fontSize: "2xl",
        px: 4,
        h: 16,
        borderRadius: "md",
    },
    "3xl": {
        fontSize: "3xl",
        px: 4,
        h: 18,
        borderRadius: "md",
    },
};

const Input = {
    variants: {
        outline: {
            field: {
                bg: "gray.50",
                borderColor: "gray.200",
            },
        },
        editable: {
            field: {
                bg: "transparent",
                left: "calc(var(--chakra-space-2) * -1)",
                right: 2,
                px: 2,
                height: "auto",
                _hover: {
                    "&:not(:focus)": {
                        bg: "blackAlpha.100",
                    },
                },
            },
            addon: {
                bg: "transparent",
                px: 0,
                height: "auto",
            },
        },
    },
    sizes: {
        sm: {
            field: sizes.sm,
            addon: sizes.sm,
        },
        xl: {
            field: sizes.xl,
            addon: sizes.xl,
        },
        "2xl": {
            field: sizes["2xl"],
            addon: sizes["2xl"],
        },
        "3xl": {
            field: sizes["3xl"],
            addon: sizes["3xl"],
        },
    },
};

export default Input;
