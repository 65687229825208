import { ComponentStyleConfig } from "@chakra-ui/react";
import type { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { getColorVar } from "@chakra-ui/theme-tools";

const variantOutline: SystemStyleFunction = props => {
    const { colorScheme: c, theme } = props;
    const borderColor = c === "gray" ? getColorVar(theme, `${c}.200`) : getColorVar(theme, `${c}.500`);
    const color = c === "gray" ? getColorVar(theme, `${c}.800`) : getColorVar(theme, `${c}.500`);

    return {
        color,
        boxShadow: `inset 0 0 0px 1px ${borderColor}`,
    };
};

const Badge: ComponentStyleConfig = {
    variants: {
        outline: variantOutline,
    },
};

export default Badge;
