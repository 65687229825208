import { Persona, PersonaAction, PersonaState } from "~/products/persona-toggle/hooks/usePersonaState";
import { Dispatch, useEffect } from "react";
import useUpdatePersona from "~/products/persona-toggle/hooks/useUpdatePersona";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";
import { MutationUpdate_ProfileArgs } from "~/types/graphql-dpa";

export default function useSyncPersonaChangesToCms(state: PersonaState, dispatch: Dispatch<PersonaAction>) {
    // A persona can sync to the CMS based on a user action or a detected persona. Account
    // for both and dispatch a unique action for each scenario upon completion, so we may
    // mutate state accordingly.
    useSyncPersonaWithDispatchingAction(
        state.nominatedPersonaCurrentlySyncingToCms,
        dispatch,
        "NOMINATED_PERSONA_SYNC_TO_CMS_COMPLETE",
    );
    useSyncPersonaWithDispatchingAction(
        state.detectedPersonaCurrentlySyncingToCms,
        dispatch,
        "DETECTED_PERSONA_SYNC_TO_CMS_COMPLETE",
    );
}

function useSyncPersonaWithDispatchingAction(
    persona: Persona | undefined,
    dispatch: Dispatch<PersonaAction>,
    actionName: "NOMINATED_PERSONA_SYNC_TO_CMS_COMPLETE" | "DETECTED_PERSONA_SYNC_TO_CMS_COMPLETE",
) {
    const { updatePersona } = useUpdatePersona();
    const { currentUser } = useCurrentDpaUser();
    useEffect(() => {
        if (persona === undefined) {
            return;
        }
        if (currentUser === null) {
            setTimeout(() => {
                dispatch({
                    name: actionName,
                });
            }, 400);
            return;
        }

        updatePersona({
            variables: {
                persona: persona,
            } as any as MutationUpdate_ProfileArgs,
            refetchQueries: ["UserMarketingPreferences"],
        }).then(response => {
            dispatch({
                name: actionName,
            });
        });
    }, [persona, updatePersona, actionName, currentUser, dispatch]);
}
