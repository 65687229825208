import { ComponentStyleConfig } from "@chakra-ui/react";

const List: ComponentStyleConfig = {
    baseStyle: {
        item: {
            textStyle: "body",
        },
    },
};

export default List;
