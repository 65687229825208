import { ComponentStyleConfig } from "@chakra-ui/react";
import textStyles from "../textStyles";

const Heading: ComponentStyleConfig = {
    sizes: {
        h1: textStyles.h1,
        h2: textStyles.h2,
        h3: textStyles.h3,
        h4: textStyles.h4,
        h5: textStyles.h5,
        h6: textStyles.h6,
    },
};

export default Heading;
