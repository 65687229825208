export const transitionPresets = {
    shadow: "0.1s ease-in-out",
    margin: "0.3s ease-in-out",
    default: "0.2s ease-in-out",
};

const getTransitionPreset = (transitionProperty: string) => {
    if (transitionProperty.includes("shadow")) {
        return transitionPresets.shadow;
    }
    if (transitionProperty.includes("margin")) {
        return transitionPresets.margin;
    }
    return transitionPresets.default;
};

const transition = (transitionProperty: string) => {
    const preset = getTransitionPreset(transitionProperty);
    return `${transitionProperty} ${preset}`;
};

export default transition;
