import { Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { seededRandom } from "~/helpers/seededRandom";

export interface EngagingSpinnerProps {
    content?: string;
}

export default function EngagingSpinner({ content }: EngagingSpinnerProps) {
    return (
        <VStack w="full" flexGrow={1} spacing={6} justifyContent="center">
            <Center>
                <Spinner />
            </Center>
            <Text>{content ? content : getEngagingMessage()}</Text>
        </VStack>
    );
}

// This method uses seeded random to help with testing
export const getEngagingMessage = (seed?: string) => {
    const randomisedMessages = seededRandom(messages, seed);
    return randomisedMessages[0];
};

export const messages = [
    "We're preparing your experience",
    "Getting everything ready",
    "Just a moment",
    "Gathering important information",
    "Hold tight, we're loading",
    "This won't take long",
    "Loading",
];
