import { gql, useQuery } from "@apollo/client";
import { dpaClient } from "~/api/graphql";
import { InnovatorJob, InnovatorJobParticipationTerms } from "~/types/graphql-dpa";
import { useEffect } from "react";
import { microProfileFragment, MicroProfileSchema } from "~/products/job-participation/helpers/microProfileSchema";

export type LoadedInnovatorJob = Omit<InnovatorJob, "participation_terms" | "participant_profiles" | "participants"> & {
    participation_terms: Exclude<InnovatorJobParticipationTerms, "application_content">;
};

export default function useInnovatorJob(jobId: string) {
    const query = useQuery<{
        innovator_job: LoadedInnovatorJob;
    }>(
        gql`
            query InnovatorJobQuery($id: JobID!) {
                innovator_job(id: $id) {
                    id
                    alias
                    name
                    byline
                    closing_date
                    launch_date
                    thumbnail

                    participation {
                        registered_on
                        gitlab_project
                        chat_session_requested
                        full_description
                        rules
                    }

                    participation_terms {
                        id
                        in_active_stage
                        privacy
                        has_pending_application
                        terms_requiring_signature
                        application_criteria
                        has_invitation
                    }

                    description_teaser

                    type
                    type_label
                    reward_structure {
                        label
                        summary
                        buckets_summary
                        total_reward
                        reward_buckets {
                            label
                            bucket_reward
                            bucket_summary
                        }
                    }

                    timeline {
                        milestone
                        date
                        description
                        is_complete
                        time_sensitive
                    }

                    leaderboard_type

                    leaderboard {
                        rank
                        score
                        account_id
                        items {
                            score_amount
                            explanation
                        }
                    }

                    rewards {
                        recipient
                        reward_id
                        reward_label
                        reward_value
                    }
                }
            }
        `,
        {
            variables: { id: jobId },
            client: dpaClient,
        },
    );

    // Auto-poll for the innovator job, as long as their gitlab project is still provisioning.
    useEffect(() => {
        const participation = query.data?.innovator_job?.participation;
        if (!participation) {
            return;
        }
        if (!participation.gitlab_project) {
            query.startPolling(4000);
        } else {
            query.stopPolling();
        }
        return () => query.stopPolling();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.data?.innovator_job?.participation]);

    return query;
}

export function useInnovatorJobParticipants(jobId: string) {
    return useQuery<{
        innovator_job: Pick<InnovatorJob, "id"> & {
            participant_profiles: MicroProfileSchema[];
        };
    }>(
        gql`
            query InnovatorJobParticipantsQuery($id: JobID!) {
                innovator_job(id: $id) {
                    id
                    participant_profiles {
                        ${microProfileFragment}
                    }
                }
            }
        `,
        {
            variables: { id: jobId },
            client: dpaClient,
        },
    );
}

export function useInnovatorJobName(jobId: string) {
    return useQuery<{
        innovator_job: Pick<InnovatorJob, "id" | "name">;
    }>(
        gql`
            query InnovatorJobNameQuery($id: JobID!) {
                innovator_job(id: $id) {
                    id
                    name
                }
            }
        `,
        {
            variables: { id: jobId },
            client: dpaClient,
        },
    );
}
