import { useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { MutationFunctionOptions, MutationHookOptions, MutationTuple } from "@apollo/client/react/types/types";
import { ApolloCache, DefaultContext, OperationVariables } from "@apollo/client/core";
import { useLogError } from "~/common/hooks/useLogError";
import { getErrorString } from "~/helpers/errors";
import { useCallback } from "react";

export default function useToastedErrorMutation<
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>,
>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables, TContext> & {
        rethrowAfterToast?: boolean;
    },
): MutationTuple<TData, TVariables, TContext, TCache> {
    const logError = useLogError();
    const [execute, result] = useMutation<TData, TVariables, TContext, TCache>(mutation, options);

    const executeAndCatch = useCallback(
        function (executeOptions?: MutationFunctionOptions<TData, TVariables, TContext, TCache>) {
            return execute(executeOptions).catch(e => {
                logError(getErrorString(e));
                if (options?.rethrowAfterToast) {
                    throw e;
                }
            });
        },
        [execute, logError, options?.rethrowAfterToast],
    );

    // @ts-ignore - not sure why these types don't align.
    return [executeAndCatch, result];
}
