import React, { Dispatch, useContext, useEffect } from "react";
import { getPersisted, setPersisted } from "~/helpers/persist";
import useReducerWithMiddlewares from "~/common/hooks/useReducerWithMiddlewares";
import useSyncPersonaChangesToCms from "~/products/persona-toggle/hooks/useSyncPersonaChangesToCms";
import labelFromPersona from "~/products/persona-toggle/util/labelFromPersona";
import useHydratePersonaStateFromCms from "~/products/persona-toggle/hooks/useHydratePersonaStateFromCms";
import useResetPersonaAfterLogout from "~/products/persona-toggle/hooks/useResetPersonaAfterLogout";

export enum Persona {
    Innovator = "data_professional",
    Industry = "industry_leader",
}

export type PersonaState = {
    currentPersona: Persona;
    otherPersona: Persona;
    otherPersonaLabel: string;
    currentPersonaLabel: string;
    isPersonaToggleModalOpen: boolean;
    manualToggleCountForSession: number;
    nominatedPersonaCurrentlySyncingToCms?: Persona;
    detectedPersonaCurrentlySyncingToCms?: Persona;
    detectedPersona?: Persona;
    cmsHydratedPersona?: Persona;
};

const defaultState: PersonaState = {
    currentPersona: Persona.Innovator,
    currentPersonaLabel: labelFromPersona(Persona.Innovator),
    otherPersona: Persona.Industry,
    otherPersonaLabel: labelFromPersona(Persona.Industry),
    isPersonaToggleModalOpen: false,
    nominatedPersonaCurrentlySyncingToCms: undefined,
    detectedPersonaCurrentlySyncingToCms: undefined,
    manualToggleCountForSession: 0,
    detectedPersona: undefined,
    cmsHydratedPersona: undefined,
};

export type PersonaAction =
    | {
          name:
              | "PERSONA_TOGGLED"
              | "INDUSTRY_PERSONA_DETECTED_BASED_ON_HAVING_ACTIVE_CROWD_ENGAGEMENT"
              | "INDUSTRY_PERSONA_DETECTED_BASED_ON_PROJECTS_PATH"
              | "PERSONA_TOGGLE_MODAL_CLOSED"
              | "NOMINATED_PERSONA_SYNC_TO_CMS_COMPLETE"
              | "DETECTED_PERSONA_SYNC_TO_CMS_COMPLETE"
              | "PERSONA_RESET_FOR_LOGGED_OUT_USER";
      }
    | {
          name: "USER_CLICKED_CHANGE_PERSONA_TOGGLE";
          requestedPersona: Persona;
      }
    | {
          name: "USER_CONFIRMED_PERSONA_CHANGE" | "USER_CONFIRMED_PERSONA_CHANGE_FROM_SETTINGS_FORM";
          newPersona: Persona;
      }
    | {
          name: "PERSONA_HYDRATED_FROM_CMS_CURRENT_USER_QUERY";
          persona: Persona;
      };

function personaReducer(state: PersonaState, action: PersonaAction): PersonaState {
    if (action.name === "USER_CLICKED_CHANGE_PERSONA_TOGGLE") {
        return {
            ...state,
            isPersonaToggleModalOpen: true,
        };
    }

    if (action.name === "PERSONA_TOGGLE_MODAL_CLOSED") {
        return {
            ...state,
            isPersonaToggleModalOpen: false,
        };
    }

    if (
        action.name === "USER_CONFIRMED_PERSONA_CHANGE" ||
        action.name === "USER_CONFIRMED_PERSONA_CHANGE_FROM_SETTINGS_FORM"
    ) {
        return {
            ...state,
            nominatedPersonaCurrentlySyncingToCms: action.newPersona,
        };
    }

    if (action.name === "PERSONA_HYDRATED_FROM_CMS_CURRENT_USER_QUERY") {
        // If we are hydrating from the current CMS user and we have already detected
        // a persona, we should leave the existing detected persona in tact and additionally
        // sync it to the CMS if it is different from the CMS persona.
        if (state.detectedPersona) {
            if (state.detectedPersona !== action.persona) {
                return {
                    ...state,
                    detectedPersonaCurrentlySyncingToCms: state.detectedPersona,
                };
            }
            return state;
        }

        // If no persona has been detected, we can safely apply the CMS persona to the
        // current session.
        return {
            ...state,
            currentPersona: action.persona,
            cmsHydratedPersona: action.persona,
        };
    }

    if (
        [
            "INDUSTRY_PERSONA_DETECTED_BASED_ON_HAVING_ACTIVE_CROWD_ENGAGEMENT",
            "INDUSTRY_PERSONA_DETECTED_BASED_ON_PROJECTS_PATH",
        ].includes(action.name)
    ) {
        // Sometimes detection should be ignored, because it will trigger during a normal browsing session for someone
        // who is manually visiting the other persona.
        const shouldIgnoreDetectionForManuallyToggledSession =
            action.name === "INDUSTRY_PERSONA_DETECTED_BASED_ON_HAVING_ACTIVE_CROWD_ENGAGEMENT";
        if (shouldIgnoreDetectionForManuallyToggledSession && state.manualToggleCountForSession > 0) {
            return state;
        }

        // If we have detected a persona as industry, after the CMS user has loaded,
        // we must ensure the new detected persona is synced to the CMS, to override
        // the incorrect record in the CMS.
        if (state.cmsHydratedPersona && state.cmsHydratedPersona !== Persona.Industry) {
            return {
                ...state,
                detectedPersonaCurrentlySyncingToCms: Persona.Industry,
                currentPersona: Persona.Industry,
                detectedPersona: Persona.Industry,
            };
        }

        return {
            ...state,
            currentPersona: Persona.Industry,
            detectedPersona: Persona.Industry,
        };
    }

    if (action.name === "NOMINATED_PERSONA_SYNC_TO_CMS_COMPLETE") {
        return {
            ...state,
            currentPersona: state.nominatedPersonaCurrentlySyncingToCms!,
            cmsHydratedPersona: state.nominatedPersonaCurrentlySyncingToCms!,
            nominatedPersonaCurrentlySyncingToCms: undefined,
            isPersonaToggleModalOpen: false,
            manualToggleCountForSession: state.manualToggleCountForSession + 1,
        };
    }

    if (action.name === "DETECTED_PERSONA_SYNC_TO_CMS_COMPLETE") {
        return {
            ...state,
            detectedPersonaCurrentlySyncingToCms: undefined,
            cmsHydratedPersona: state.nominatedPersonaCurrentlySyncingToCms!,
        };
    }

    if (action.name === "PERSONA_RESET_FOR_LOGGED_OUT_USER") {
        return {
            ...state,
            currentPersona: Persona.Innovator,
        };
    }

    return state;
}

export function usePersonaReducer(): [PersonaState, Dispatch<PersonaAction>] {
    // Import default state from local storage, if possible.
    const [state, dispatcher] = useReducerWithMiddlewares(
        personaReducer,
        null,
        () => {
            const cachedPersona = getPersisted("cachedPersona");
            return cachedPersona
                ? {
                      ...defaultState,
                      currentPersona: cachedPersona,
                  }
                : defaultState;
        },
        [
            state => ({
                ...state,
                otherPersona: state.currentPersona === Persona.Industry ? Persona.Innovator : Persona.Industry,
            }),
            state => ({
                ...state,
                otherPersonaLabel: labelFromPersona(state.otherPersona),
                currentPersonaLabel: labelFromPersona(state.currentPersona),
            }),
        ],
    );

    // Persist state to local storage.
    useEffect(() => setPersisted("cachedPersona", state.currentPersona), [state.currentPersona]);

    useSyncPersonaChangesToCms(state, dispatcher);
    useHydratePersonaStateFromCms(state, dispatcher);
    useResetPersonaAfterLogout(state, dispatcher);

    return [state, dispatcher];
}

export const PersonaContext = React.createContext<[PersonaState, Dispatch<PersonaAction>] | undefined>(undefined);
export default function usePersonaState() {
    const context = useContext(PersonaContext);
    if (!context) {
        throw new Error("Missing persona context");
    }
    return context;
}
