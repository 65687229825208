import { ComponentStyleConfig } from "@chakra-ui/react";
import type { SystemStyleFunction } from "@chakra-ui/theme-tools";
import Badge from "./badge";

const Tag: ComponentStyleConfig = {
    parts: ["container", "label", "closeButton"],
    variants: {
        outline: props => ({
            container: (Badge.variants?.outline as SystemStyleFunction)(props),
        }),
    },
};

export default Tag;
