import { ComponentStyleConfig } from "@chakra-ui/react";

const Avatar: ComponentStyleConfig = {
    baseStyle: {
        container: {
            backgroundColor: "brand.500",
            color: "white",
        },
    },
};

export default Avatar;
