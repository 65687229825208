import * as React from "react";
import { useLocation } from "react-router-dom";

/**
 * Routes to exclude from attempting to continue to.
 *
 * These are generally routes that we wouldn't want authenticated users to be
 * forwarded to (typically because they're apart of the login/registration
 * process).
 */
const EXCLUDE_FROM_CONTINUE = ["/login", "/register", "/verify-email", "/registration-intent"];

/**
 * Returns a function to construct a url to the given route with a continue
 * param set as the current route.
 */
const useGetUrlWithContinue = () => {
    const location = useLocation();

    const getUrlWithContinue = React.useCallback(
        (to: string = "") => {
            if (EXCLUDE_FROM_CONTINUE.includes(location.pathname)) {
                return to;
            }
            return `${to}?continue=${encodeURIComponent(location.pathname)}`;
        },
        [location.pathname],
    );

    return getUrlWithContinue;
};

export default useGetUrlWithContinue;
