import * as React from "react";
import {
    Box,
    Button,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    Icon,
    IconButton,
    LinkProps,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { SidebarLink } from "./Link";
import { Outlet, useLocation } from "react-router-dom";
import Link from "~/common/components/PrefetchLink";
import { BsAward } from "~/common/icons/BsAward";
import { BsBoxSeam } from "~/common/icons/BsBoxSeam";
import { BsFileText } from "~/common/icons/BsFileText";
import { BsLayers } from "~/common/icons/BsLayers";
import { BsList } from "~/common/icons/BsList";
import { BsX } from "~/common/icons/BsX";
import { MdOutlineDashboard } from "~/common/icons/MdOutlineDashboard";
import UserAvatar from "./UserAvatar";
import ProfileMenu from "~/authentication/components/ProfileMenu";
import useGetUrlWithContinue from "../hooks/useGetUrlWithContinue";
import Logo from "~/common/components/icons/Logo";
import LogoIcon from "~/common/components/icons/LogoIcon";
import { IconType } from "react-icons";
import usePersonaState, { Persona } from "~/products/persona-toggle/hooks/usePersonaState";
import { useDetectPersonaBasedOnPath } from "~/products/persona-toggle/hooks/useDetectPersonaBasedOnPath";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";

export type SidebarItemOptions = {
    label: string;
    icon?: IconType;
    to: string;
};

interface SidebarItemProps extends SidebarItemOptions, LinkProps {}

const SidebarItem = ({ label, icon, to, ...rest }: SidebarItemProps) => {
    const location = useLocation();

    return (
        <SidebarLink
            isActive={to === "/" ? location.pathname === to : location.pathname.indexOf(to) === 0}
            as={Link}
            to={to}
            {...rest}
        >
            {icon ? (
                <Stack alignItems="center" direction="row" spacing={3} px={1}>
                    <Icon as={icon} boxSize={6} color="grey.800" />
                    <Text fontWeight="normal" fontSize="sm">
                        {label}
                    </Text>
                </Stack>
            ) : (
                <Text px={2} fontWeight="600" fontSize={14} color="gray.800" textTransform="uppercase">
                    {label}
                </Text>
            )}
        </SidebarLink>
    );
};

const iconButtonProps = {
    colorScheme: "gray",
    fontSize: "2xl",
    size: "md",
    variant: "ghost",
};

const menus = {
    [Persona.Innovator]: [
        {
            label: "Dashboard",
            icon: MdOutlineDashboard,
            to: "/",
        },
        {
            label: "Challenges",
            icon: BsAward,
            to: "/challenges",
        },
        {
            label: "Datasets",
            icon: BsLayers,
            to: "/datasets",
        },
        {
            label: "Documentation",
            icon: BsFileText,
            to: "/docs",
        },
    ],
    [Persona.Industry]: [
        {
            label: "Dashboard",
            icon: MdOutlineDashboard,
            to: "/",
        },
        {
            label: "Projects",
            icon: BsBoxSeam,
            to: "/projects",
        },
        {
            label: "Datasets",
            icon: BsLayers,
            to: "/datasets",
        },
    ],
};

function AppMenu() {
    const [personaState] = usePersonaState();
    useDetectPersonaBasedOnPath();
    const menu = menus[personaState.currentPersona];

    return (
        <>
            {menu.map(({ ...data }, n) => {
                return <SidebarItem key={n} {...data} />;
            })}
        </>
    );
}

const AppLayout: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { loggedIn, isLoading: isCurrentUserLoading, currentUser } = useCurrentDpaUser();
    const getUrlWithContinue = useGetUrlWithContinue();

    return (
        <>
            <HStack
                justify="space-between"
                w="full"
                py={[1, 2]}
                backgroundColor="white"
                borderBottom="1px"
                borderColor="gray.200"
            >
                <HStack spacing={2}>
                    <Flex justifyContent="center" ml={[2, null, 0]} w="auto">
                        <IconButton
                            display={["inherit", null, "none"]}
                            aria-label="Mobile Menu"
                            onClick={onOpen}
                            icon={<BsList />}
                            {...iconButtonProps}
                        />
                    </Flex>
                    <Link to="/" aria-label="Home">
                        <Box h="32px" py="2px" ml={2} display={["none", null, "block"]}>
                            <Logo height="100%" />
                        </Box>
                        <Box h={7} display={["block", null, "none"]}>
                            <LogoIcon height="100%" />
                        </Box>
                    </Link>
                </HStack>

                <Box pr={4}>
                    {isCurrentUserLoading ? null : loggedIn && currentUser ? (
                        <ProfileMenu currentUser={currentUser.content}>
                            <HStack>
                                <Text display={["none", "inherit"]}>{currentUser.content.name}</Text>
                                <UserAvatar size="sm" user={currentUser.content} />
                            </HStack>
                        </ProfileMenu>
                    ) : (
                        <HStack>
                            <Button as={Link} size="sm" to={getUrlWithContinue("/register")} variant="outline">
                                Register
                            </Button>
                            <Button as={Link} size="sm" to={getUrlWithContinue("/login")}>
                                Sign in
                            </Button>
                        </HStack>
                    )}
                </Box>
            </HStack>
            <Flex flex="1" bg="gray.50">
                {/* Desktop and Tablet Sidebar */}
                <VStack
                    as="aside"
                    display={["none", null, "inherit"]}
                    py={4}
                    backgroundColor="white"
                    borderRight="1px"
                    borderColor="gray.200"
                    flex="0 0 200px"
                    alignItems="stretch"
                    w="full"
                >
                    <AppMenu />
                </VStack>

                {/* Mobile Drawer */}
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <HStack justify="space-between" p={4}>
                            <Box ml={3} mt={1}>
                                <Link onClick={onClose} to="/" aria-label="Home">
                                    <Box h={7}>
                                        <Logo height="100%" />
                                    </Box>
                                </Link>
                            </Box>
                            <IconButton aria-label="Close" onClick={onClose} icon={<BsX />} {...iconButtonProps} />
                        </HStack>
                        <VStack alignItems="stretch" w="full">
                            <AppMenu />
                        </VStack>
                    </DrawerContent>
                </Drawer>
                <Flex as="main" direction="column" flexGrow={1} minW="0">
                    <Outlet />
                </Flex>
            </Flex>
        </>
    );
};

export default AppLayout;
