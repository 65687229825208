import { chakra, forwardRef, HStack, Icon, Link as ChakraLink, LinkProps, Text } from "@chakra-ui/react";
import { BsFileRichtext } from "~/common/icons/BsFileRichtext";
import transition from "~/helpers/transition";
import { NavLink, NavLinkProps } from "react-router-dom";

export const FileLink = forwardRef<LinkProps, "a">((props, ref) => {
    const { children, ...rest } = props;
    return (
        <ChakraLink target="_blank" download {...rest} ref={ref}>
            <HStack align="center" spacing={1}>
                <Icon as={BsFileRichtext} />
                <Text wordBreak="break-word">{children}</Text>
            </HStack>
        </ChakraLink>
    );
});

export type NavListLinkProps = Omit<NavLinkProps, "className" | "style" | "as"> & { isActive?: boolean } & LinkProps;
export const NavListLink = forwardRef<NavListLinkProps, "a">((props, ref) => {
    return (
        <ChakraLink
            as={NavLink}
            // @ts-expect-error This is the type from NavLinkProps
            // but chakra thinks it's using its attribute here
            style={({ isActive }) =>
                isActive || props.isActive ? { borderColor: "var(--chakra-colors-brand-500)" } : {}
            }
            borderLeftWidth="2px"
            borderColor="transparent"
            pl={2}
            textDecoration="none"
            fontWeight="500"
            ref={ref}
            {...props}
        />
    );
});

export const SidebarLink = forwardRef<LinkProps & { isActive: boolean }, "a">((props, ref) => {
    const { children, isActive, ...rest } = props;
    return (
        <chakra.a
            ref={ref}
            width="100%"
            px={2}
            py={2}
            cursor="pointer"
            transition={transition("background")}
            userSelect="none"
            bg={isActive ? "gray.100" : "transparent"}
            _hover={{
                bg: isActive ? "gray.100" : "gray.50",
            }}
            _focus={{
                bg: "gray.100",
            }}
            _active={{
                bg: "gray.100",
            }}
            {...rest}
        >
            {children}
        </chakra.a>
    );
});
