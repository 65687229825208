import { ComponentStyleConfig } from "@chakra-ui/react";

const Table: ComponentStyleConfig = {
    defaultProps: {
        colorScheme: "gray",
        size: "sm",
    },
    baseStyle: {
        tr: {
            _last: {
                td: {
                    borderBottom: 0,
                },
            },
        },
    },
};

export default Table;
