import * as React from "react";
import { ErrorBoundary as RawErrorBoundary } from "react-error-boundary";
import { PageNotFoundError } from "~/types/errors";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { ErrorFallback } from "./ErrorFallback";
import MaintenancePage from "./MaintenancePage";
import PageNotFound, { PageNotFoundProps } from "./PageNotFound";

interface SpecificErrorBoundaryProps extends ErrorBoundaryProps {
    /**
     * Always catches errors that matches conditions
     */
    condition: (error: Error) => boolean;
}

/**
 * Specific Error Boundary
 *
 * An error boundary that catches errors that match the provided condition,
 * otherwise it rethrows the error.
 */
export const SpecificErrorBoundary: React.FC<SpecificErrorBoundaryProps> = ({
    children,
    condition,
    Fallback = ErrorFallback,
}) => {
    return (
        <RawErrorBoundary
            fallbackRender={({ error, ...props }) => {
                if (condition(error)) {
                    return <Fallback error={error} {...props} />;
                }
                throw error;
            }}
        >
            {children}
        </RawErrorBoundary>
    );
};

/**
 * Error Boundary for Maintenance Mode
 */
export const maintenanceModeCondition = (e: Error) => {
    // @ts-ignore
    return e.statusCode === 503;
};
export const MaintenanceModeBoundary: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <SpecificErrorBoundary Fallback={MaintenancePage} condition={maintenanceModeCondition}>
            {children}
        </SpecificErrorBoundary>
    );
};

/**
 * Error Boundary for PageNotFound
 */
export const pageNotFoundCondition = (e: Error) => {
    return e instanceof PageNotFoundError;
};
export const PageNotFoundBoundary: React.FC<Partial<PageNotFoundProps>> = ({ children, ...props }) => {
    return (
        <SpecificErrorBoundary
            Fallback={({ error }) => <PageNotFound mt="4" body={error.message} {...props} />}
            condition={pageNotFoundCondition}
        >
            {children}
        </SpecificErrorBoundary>
    );
};
