import * as React from "react";
import useToast from "../components/useToast";

interface Options {
    title: string;
    description?: string;
}

export type LogError = (toastMessage: string | null | Options, ...errorArgs: any[]) => void;

export const useLogError = (shouldNotifyUser: boolean = true): LogError => {
    const toast = useToast();

    const logError = React.useCallback(
        (toastMessage: string | null | Options, ...errorArgs: any[]) => {
            console.error(...errorArgs);

            if (shouldNotifyUser) {
                if (toastMessage && typeof toastMessage === "object") {
                    toast({
                        status: "error",
                        ...toastMessage,
                    });
                } else {
                    toast({
                        status: "error",
                        title: "Something went wrong",
                        description: toastMessage || "An unexpected error occurred",
                    });
                }
            }
        },
        [shouldNotifyUser, toast],
    );

    return logError;
};
