import * as React from "react";
import { useToast as useChakraToast, UseToastOptions } from "@chakra-ui/react";

const useToast = (props?: UseToastOptions) => {
    const withDefaultProps: UseToastOptions = React.useMemo(
        () => ({
            position: "top",
            isClosable: true,
            delay: 2000,
            ...props,
        }),
        [props],
    );
    return useChakraToast(withDefaultProps);
};

export default useToast;
