import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";
import * as React from "react";
import Link from "~/common/components/PrefetchLink";
import useAuthentication from "../hooks/useAuthentication";
import { PublicProfileContent } from "~/types/graphql-dpa";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";

interface Props {
    currentUser: Pick<PublicProfileContent, "alias" | "account_id">;
    children?: React.ReactNode;
}

const ProfileMenu: React.FC<Props> = ({ children, currentUser }) => {
    const { logout } = useAuthentication();
    const { isAdmin } = useCurrentDpaUser();

    return (
        <Menu placement="bottom-end">
            <MenuButton>{children}</MenuButton>
            <MenuList>
                <MenuItem as={Link} to={`/profile/${currentUser.alias}`}>
                    My Profile
                </MenuItem>
                <MenuItem as={Link} to="/settings">
                    Settings
                </MenuItem>

                {isAdmin && (
                    <MenuItem as={Link} to="/platform">
                        Platform tools
                    </MenuItem>
                )}

                <MenuItem as={Link} to="/referral-link">
                    Refer & Earn
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={logout}>Log Out</MenuItem>
            </MenuList>
        </Menu>
    );
};

export default ProfileMenu;
