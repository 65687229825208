import { Navigate, useLocation } from "react-router-dom";
import useGetUrlWithContinue from "../hooks/useGetUrlWithContinue";
import { useDelayedRender } from "./DeferredRender";
import EngagingSpinner from "./EngagingSpinner";
import PageNotFound from "./PageNotFound";
import { useMixpanel } from "~/common/analytics";
import * as React from "react";
import { useEffect } from "react";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";
import { useIsEmailVerified } from "~/common/hooks/useIsEmailVerified";

type ProtectedComponentProps = {
    Component?: React.ComponentType;
    children?: React.ReactNode;
    accessRequired: string[];
    requireResolvedUser?: boolean;
    mixpanelEvent?: string;
    preferRegisterForAccessProtection?: boolean;
};

function TrackMixpanelEvent({ eventName }: { eventName?: string }) {
    const mixpanel = useMixpanel();
    const location = useLocation();
    useEffect(() => {
        if (eventName) {
            mixpanel?.track(eventName);
        }
    }, [eventName, mixpanel, location]);
    return null;
}

/**
 * Custom route to add conditional guards against rendering.
 */
const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
    accessRequired,
    Component,
    children,
    mixpanelEvent,
    preferRegisterForAccessProtection,
    requireResolvedUser,
}) => {
    const location = useLocation();
    const { query, isAdmin } = useCurrentDpaUser();
    const shouldRender = useDelayedRender(200);
    const getUrlWithContinue = useGetUrlWithContinue();

    const emailVerified = useIsEmailVerified();

    const pageContent = (
        <>
            <TrackMixpanelEvent eventName={mixpanelEvent} />
            {Component && <Component />}
            {children}
        </>
    );

    if (accessRequired.length === 0 && !requireResolvedUser) {
        return pageContent;
    }

    if (query.loading) {
        // Don't flash the spinner if its going to load in under 200ms
        return shouldRender ? <EngagingSpinner content="Loading..." /> : null;
    }

    // For protected routes, redirect anonymous users to the login page.
    // Adding location.pathname !== "/login" prevents infinite loops in tests.
    if (
        location.pathname !== "/login" &&
        accessRequired.includes("authenticated") &&
        !query.data?.current_user_profile
    ) {
        return <Navigate replace to={getUrlWithContinue(preferRegisterForAccessProtection ? "/register" : "/login")} />;
    }

    // Force users to verify their account as soon as they log in.
    if (
        location.pathname !== "/verify-email" &&
        location.pathname.indexOf("/embed") !== 0 &&
        query.data?.current_user_profile?.account_id &&
        emailVerified === false
    ) {
        return <Navigate replace to={getUrlWithContinue("/verify-email")} />;
    }

    // Access control admin-only pages.
    if (accessRequired.includes("platform_manager") && !isAdmin) {
        return <PageNotFound mt="4" />;
    }

    return pageContent;
};

export default ProtectedComponent;
