import { IconBaseProps } from "react-icons";
import { TypeIgnoreGenIcon as GenIcon } from "~/common/icons/index";
export function BsBoxSeam(props: IconBaseProps) {
    return GenIcon({
        tag: "svg",
        attr: { fill: "currentColor", viewBox: "0 0 16 16" },
        child: [
            {
                tag: "path",
                attr: {
                    d: "M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z",
                },
            },
        ],
    })(props);
}
