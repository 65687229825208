import FileRouter from "./FileRouter";
import { ErrorFallback } from "./common/components/ErrorFallback";
import useIdentifyUsers from "./common/analytics/useIdentifyUsers";
import ScrollToTop from "./common/components/ScrollToTop";
import { Flex } from "@chakra-ui/react";
import { MaintenanceModeBoundary } from "./common/components/SpecificErrorBoundary";
// We use the raw error boundary here to ensure no errors slip past this point
import { ErrorBoundary as RawErrorBoundary } from "react-error-boundary";
import { PersonaContext, usePersonaReducer } from "~/products/persona-toggle/hooks/usePersonaState";
import useCaptureReferralCode from "~/authentication/hooks/useCaptureReferralCode";
import { useEffect } from "react";
import { getToken } from "~/api/graphql";

function App() {
    const personaReducer = usePersonaReducer();
    useIdentifyUsers();
    useCaptureReferralCode();

    useEffect(() => {
        // Pre-load the DPA auth token, to prevent this from blocking the request waterfall later on in a pages
        // execution.
        getToken();
    }, []);

    return (
        <PersonaContext.Provider value={personaReducer}>
            <Flex minH="100vh" direction="column">
                <RawErrorBoundary FallbackComponent={ErrorFallback}>
                    <MaintenanceModeBoundary>
                        <ScrollToTop />
                        <FileRouter />
                    </MaintenanceModeBoundary>
                </RawErrorBoundary>
            </Flex>
        </PersonaContext.Provider>
    );
}

export default App;
