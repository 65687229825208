import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
    baseStyle: ({ colorScheme }) => ({
        cursor: "pointer",
        ".chakra-button__icon": {
            color: "inherit",
        },
        _focus: {
            boxShadow: `0 0 0 3px var(--chakra-colors-${colorScheme}-200)`,
        },
    }),
    variants: {
        solid: ({ colorScheme }) => {
            let baseColorValue = 500;
            if (colorScheme === "gray") {
                baseColorValue = 200;
            }
            return {
                backgroundColor: `${colorScheme}.${baseColorValue}`,
                _hover: {
                    backgroundColor: `${colorScheme}.${baseColorValue + 100}`,
                    _disabled: {
                        backgroundColor: `${colorScheme}.${baseColorValue}`,
                    },
                },
                _active: {
                    backgroundColor: `${colorScheme}.${baseColorValue + 200}`,
                },
                _focus: {
                    backgroundColor: `${colorScheme}.${baseColorValue + 100}`,
                },
            };
        },
        ghost: ({ colorScheme }) => ({
            color: colorScheme === "brand" || colorScheme === "gray" ? "gray.800" : `${colorScheme}.500`,
            ".chakra-button__icon": {
                color: colorScheme === "gray" ? "gray.800" : `${colorScheme}.500`,
            },
            _focus: {
                backgroundColor: `${colorScheme}.50`,
            },
        }),
        outline: ({ colorScheme }) => ({
            color: "gray.800",
            backgroundColor: "white",
            borderColor: colorScheme === "gray" ? "gray.200" : `${colorScheme}.500`,
            _hover: {
                backgroundColor: `${colorScheme}.50`,
                _disabled: {
                    backgroundColor: "white",
                },
            },
            _disabled: {
                backgroundColor: "white",
            },
            _active: {
                backgroundColor: `${colorScheme}.100`,
            },
            _focus: {
                backgroundColor: `${colorScheme}.50`,
            },
        }),
        link: ({ colorScheme }) => ({
            color: "gray.800",
            textDecoration: "underline",
            _hover: {
                color: "gray.700",
                _disabled: {
                    color: "gray.800",
                    textDecoration: "underline",
                },
            },
            _active: {
                color: "gray.500",
            },
            _focus: {
                color: "gray.700",
            },
        }),
    },
    sizes: {
        xl: {
            h: 14,
            minW: 14,
            fontSize: "xl",
            px: 8,
        },
        "2xl": {
            h: 16,
            minW: 16,
            fontSize: "2xl",
            px: 8,
        },
    },
};

export default Button;
