import { useCallback } from "react";
import { useLogError } from "~/common/hooks/useLogError";
import { resetToken } from "~/api/graphql";
import { QueryResult } from "@apollo/client";
import Link from "~/common/components/PrefetchLink";
import { AlertStatus, Link as ChakraLink } from "@chakra-ui/react";
import useToast from "~/common/components/useToast";
import { useNavigate } from "react-router-dom";
import { getUserReferral } from "~/authentication/hooks/useCaptureReferralCode";
import { useCurrentDpaUser } from "~/authentication/hooks/useCurrentDpaUser";
import { login, logout, register } from "~/api/auth";

export interface LoginValues {
    username: string;
    password: string;
}

export interface RegisterValues {
    email: string;
    password: string;
    persona: string;
    confirmPassword: string;
    fullName: string;
    hasAgreedToTerms: boolean;
    captcha: string;
}

export interface AuthResponse {
    error: Error | null;
    success: boolean;
}

export const getLoginErrorElement = (message: string): JSX.Element => {
    switch (message) {
        case 'Hey, we\'re updating our portal and your password needs updating. Please <a href="/user/password">click here</a> to upgrade your password.':
            return (
                <span>
                    Hey, we're updating our portal and your password needs updating. Please{" "}
                    <ChakraLink as={Link} to="/reset-password">
                        click here
                    </ChakraLink>{" "}
                    to upgrade your password.
                </span>
            );
        default:
            return <>{message}</>;
    }
};
/**
 * Refetches queries after register/login/logout
 */
export const refetchQueries = async (currentUserQuery: QueryResult) => {
    // Get the new logged in user
    await currentUserQuery.refetch();
    // Then we reset the auth token for dpaClient
    await resetToken();
};

/**
 * State management for user (info and authentication).
 */
const useAuthentication = () => {
    const { query } = useCurrentDpaUser();
    const logError = useLogError(false);
    const toast = useToast();
    const navigate = useNavigate();

    const handleRegister = useCallback(
        async ({
            email,
            password,
            confirmPassword,
            fullName,
            persona,
            hasAgreedToTerms,
            captcha,
        }: RegisterValues): Promise<AuthResponse> => {
            try {
                if (!hasAgreedToTerms) {
                    return { error: new Error("You must agree to the terms to register"), success: false };
                }
                if (password !== confirmPassword) {
                    return { error: new Error("Passwords do not match"), success: false };
                }
                const response = await register(email, fullName, getUserReferral(), persona, password, captcha);
                const json = await response.json();
                if (response.status !== 200) {
                    return {
                        error: new Error(json.message || "Failed to register."),
                        success: false,
                    };
                } else {
                    await refetchQueries(query);

                    return { error: null, success: true };
                }
            } catch (e: any) {
                logError(null, "Failed to register", e);
                return { error: new Error("Something unexpected happened"), success: false };
            }
        },
        [logError, query],
    );

    const handleLogin = useCallback(
        async ({ username, password }: LoginValues): Promise<AuthResponse> => {
            try {
                const response = await login(username, password);
                const json = await response.json();
                if (response.status !== 200) {
                    return {
                        error: new Error(json.message || "Failed to log in."),
                        success: false,
                    };
                } else {
                    await refetchQueries(query);
                    return { error: null, success: true };
                }
            } catch (e: any) {
                logError(null, "Failed to login", e, username);
                return { error: new Error("Something unexpected happened"), success: false };
            }
        },
        [logError, query],
    );

    const handleLogout = useCallback(async () => {
        const errorToast = {
            status: "error" as AlertStatus,
            title: "Something went wrong",
            description: "Failed to log out.",
        };
        try {
            const response = await logout();
            if (response.status !== 200) {
                toast(errorToast);
            } else {
                await refetchQueries(query);
                toast({
                    status: "success",
                    title: "Logged out",
                    description: "You have been logged out",
                });
                navigate("/");
            }
        } catch (e: any) {
            logError(null, "Failed to logout", e);
            toast(errorToast);
        }
    }, [logError, navigate, query, toast]);

    return {
        login: handleLogin,
        logout: handleLogout,
        register: handleRegister,
    };
};

export default useAuthentication;
