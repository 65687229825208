import { Config } from "./types";

export default {
    theme: {
        brand: {
            50: "#E9FBF1",
            100: "#C2F5D7",
            200: "#9AEFBD",
            300: "#73E8A4",
            400: "#4BE28A",
            500: "#22D16B",
            600: "#1DAF5A",
            700: "#158443",
            800: "#0E582D",
            900: "#072C16",
        },
    },
    cmsBaseUrl: import.meta.env.REACT_APP_CMS_URL || "",
    cmsStaticFileRepository: "https://d2mfsd50sy8nr0.cloudfront.net",
    dpaBaseUrl: import.meta.env.REACT_APP_DPA_URL || "",
    authServiceUrl: import.meta.env.REACT_APP_AUTH_SERVICE_URL || "",
    algoliaApiKey: import.meta.env.REACT_APP_ALGOLIA_APP_KEY || "",
    algoliaAppId: import.meta.env.REACT_APP_ALGOLIA_APP_ID || "",
    algoliaAlexandriaIndexName: import.meta.env.REACT_APP_ALGOLIA_ALEXANDRIA_INDEX_NAME || "",
    algoliaForumIndexName: import.meta.env.REACT_APP_ALGOLIA_FORUM_INDEX_NAME || "",
    algoliaCompetitionIndexName: import.meta.env.REACT_APP_ALGOLIA_COMPETITION_INDEX_NAME || "",
    mixpanelProjectToken: import.meta.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || null,
    maintenanceEnabled: import.meta.env.REACT_APP_MAINTENANCE || false,
    roomlioPublicKey: import.meta.env.REACT_APP_ROOMLIO_PUBLIC_KEY || "",
    roomlioWidgetId: import.meta.env.REACT_APP_ROOMLIO_WIDGET_ID || "",
    jobImagesCdnUrl: import.meta.env.REACT_APP_JOB_IMAGES_CDN_URL || "",
    hotjarId: import.meta.env.REACT_APP_HOTJAR_ID || null,
} as Config;
