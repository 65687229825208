import { Persona } from "~/products/persona-toggle/hooks/usePersonaState";

export default function labelFromPersona(persona: Persona): string {
    if (persona === Persona.Industry) {
        return "Business Leader";
    }
    if (persona === Persona.Innovator) {
        return "Data Professional";
    }
    return "-";
}
