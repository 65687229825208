import { Colors } from "@chakra-ui/react";
import config from "~/config/config";

const colors: Colors = {
    brand: config.theme.brand,
    gray: {
        50: "#F5F5F5",
        100: "#EDEDED",
        200: "#E5E5E5",
        300: "#D1D1D1",
        400: "#ADADAD",
        500: "#929292",
        600: "#7D7D7D",
        700: "#686868",
        800: "#3E3E3E",
        900: "#2C2C2C",
    },
};

export default colors;
