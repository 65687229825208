import { useReducer } from "react";

export default function useReducerWithMiddlewares<State, Action, InitializerArg>(
    reducer: (state: State, action: Action) => State,
    initializerArg: InitializerArg,
    initializer: (d: InitializerArg) => State,
    middlewares: Array<(state: State) => State>,
) {
    return useReducer(
        (state: State, action: Action) => {
            return middlewares.reduce(
                (value, middleware) => {
                    return middleware(value);
                },
                reducer(state, action),
            );
        },
        initializerArg,
        initializerArg => {
            return middlewares.reduce((value, middleware) => {
                return middleware(value);
            }, initializer(initializerArg));
        },
    );
}
